import { TimeEntryStatus } from 'enums/status';
import { IKaiserResponse } from 'interfaces/TimeEntry/IKaiserResponse';
import { INonPassportTimeEntry } from 'interfaces/TimeEntry/INonPassportTimeEntry';
import { ITimeEntryRequest } from 'interfaces/TimeEntry/ITimeEntry';
import { ITimesheetApprover } from 'interfaces/TimeEntry/ITimeSheetApprover';
import { ITimesheetDetails } from 'interfaces/TimeEntry/ITimesheetDetails';
import { ITimesheetRequest } from 'interfaces/TimeEntry/ITimesheetRequest';
import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export async function fetchTimesheets(timesheetReq: ITimeEntryRequest) {
  try {
    let timesheetQuery = `v2/timesheets/${timesheetReq.userId}?status=${timesheetReq.statuses}`;

    if (timesheetReq.limit) timesheetQuery += `&limit=${timesheetReq.limit}`;
    if (timesheetReq.offset) timesheetQuery += `&offset=${timesheetReq.offset}`;
    if (timesheetReq.placementId)
      timesheetQuery += `&placementId=${timesheetReq.placementId}`;

    return timesheetReq.isLocums === true
      ? await http.get<string>(timesheetQuery, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get<string>(timesheetQuery);
  } catch (error) {
    logger.error(error, 'fetchTimesheets', 'timeEntryService.ts');
    return error;
  }
}

export async function fetchTimesheet(
  userId: string,
  timesheetId: string,
  isLocums: boolean,
): Promise<ITimesheetDetails> {
  try {
    let timesheetQuery = `timesheets/${userId}/${timesheetId}`;
    return isLocums === true
      ? await http.get<ITimesheetDetails>(timesheetQuery, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get<ITimesheetDetails>(timesheetQuery);
  } catch (e: any) {
    logger.error(e, 'fetchTimesheet', 'timeEntryService.ts');
    return e;
  }
}

export async function fetchNonPassportTimesheet(
  userId: string,
  placementId: string,
  startDate: string,
  version: string,
  isLocums: boolean,
): Promise<INonPassportTimeEntry> {
  try {
    let timesheetQuery = `timesheets/${userId}/${placementId}/${startDate}`;
    if (version) timesheetQuery += `?version=${version}`;
    return isLocums === true
      ? await http.get<INonPassportTimeEntry>(timesheetQuery, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get<INonPassportTimeEntry>(timesheetQuery);
  } catch (e: any) {
    logger.error(e, 'fetchNonPassportTimesheet', 'timeEntryService.ts');
    return e;
  }
}

export async function fetchTimesheetApprovers(
  userId: string,
  placementIds: string[],
) {
  try {
    let query = `timesheets/approvers/${userId}?placementIds=${placementIds.toString()}`;
    return await http.get(query);
  } catch (e) {
    logger.error(e, 'fetchTimesheetApprovers', 'timeEntryService.ts');
    return e;
  }
}

export async function fetchTimesheetApproversForPlacement(
  userId: string,
  placementId: string,
  isLocums: boolean,
) {
  try {
    let query = `timesheets/approvers/${userId}?placementIds=${placementId.toString()}`;
    return isLocums === true
      ? await http.get(query, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get(query);
  } catch (e) {
    logger.error(
      e,
      'fetchTimesheetApproversForPlacement',
      'timeEntryService.ts',
    );
    return e;
  }
}

export async function fetchTimesheetAssignments(userId: string) {
  try {
    let query = `timesheets/${userId}/assignments?status=${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`;
    return await http.get(query);
  } catch (e) {
    logger.error(e, 'fetchTimesheetAssignments', 'timeEntryService.ts');
    return e;
  }
}

export async function postTimesheetApprover(
  userId: string,
  placementId: string,
  timesheetApprover: any,
) {
  try {
    let query = `timesheets/approvers/${userId}/${placementId}`;
    return await http.post<ITimesheetApprover>(query, timesheetApprover);
  } catch (e) {
    logger.error(e, 'postTimesheetApprover', 'timeEntryService.ts');
    return e;
  }
}

export async function updateTimesheet(
  userId: string,
  timesheetId: string,
  timesheetRequest: ITimesheetRequest,
  isLocums: boolean,
) {
  let query = `timesheets/${userId}/${timesheetId}`;
  return isLocums === true
    ? await http.put<ITimesheetRequest>(query, timesheetRequest, {
        headers: { 'x-userType': 'locums' },
      })
    : http.put<ITimesheetRequest>(query, timesheetRequest);
}

export async function resubmitTimesheet(
  userId: string,
  timesheetId: string,
  timesheetRequest: ITimesheetRequest,
  isLocums: boolean,
) {
  try {
    let query = `timesheets/${userId}/${timesheetId}`;
    return isLocums === true
      ? await http.patch<ITimesheetRequest>(query, timesheetRequest, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.patch<ITimesheetRequest>(query, timesheetRequest);
  } catch (e) {
    logger.error(e, 'resubmitTimesheet', 'timeEntryService.ts');
    return e;
  }
}

export async function postTimesheet(userId: string, timesheetId: string) {
  let query = `timesheets/${userId}/${timesheetId}`;
  return await http.post(query);
}

export async function fetchKaiserTimeSheet(userId: string) {
  try {
    return await http.get<IKaiserResponse>(`/timesheets/${userId}/settings`);
  } catch (e) {
    logger.error(e, 'fetchKaiserTimeSheet', 'timeEntryService.ts');
    return e;
  }
}

export async function fetchPreviousTimeSheetData(
  userId: string,
  placementId: number,
  startDate: string,
  isLocums: boolean,
) {
  try {
    let query = `timesheets/previous-submission/${userId}/${placementId}/${startDate}`;
    return isLocums === true
      ? await http.get(query, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get(query);
  } catch (e) {
    logger.error(e, 'fetchPreviousTimeSheetData', 'timeEntryService.ts');
    return e;
  }
}
