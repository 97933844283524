import React from 'react';
import { DocumentUploadsIcon } from 'assets/icons/CredentialsWallet/DocumentUploads';
import { EducationOnlineExamsIcon } from 'assets/icons/CredentialsWallet/EducationOnlineExams';
import { LicensesIcon } from 'assets/icons/Licenses/LicensesIcon';
import { LinksIcon } from 'assets/icons/Resources/Links';
import { PDFIcon } from 'assets/icons/Resources/PDF';
import { CredSubType } from 'enums/credSubTypeConfig';
import {
  BadgeStatus,
  Country,
  CredentialOpenRequirementsStatus,
  CredentialRejectedReason,
  CredentialSubmissionStatus,
  CredentialSubType,
  OpenRequirementCredentialType,
  SubmissionType,
  TaskCenterCategoryType,
  TaskCenterCategoryTypeId,
  userCredentials,
} from 'enums/credential';
import { LinkType } from 'enums/linkConfig';
import {
  ICredentialDetails,
  ICredentialSubmission,
  IDocuSignEnvelop,
  IOpenRequirementsDetails,
} from 'interfaces/Credential/ICredentialDetails';
import {
  getRnOrLpnContentName,
  hasExpiredOrExpiring,
  returnBadgeStatus,
  returnUrgencyIcon,
  visualIndicatorColor,
} from 'pages/TaskCenter/helper';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { theme } from 'styles/theme';
import { getFormattedDateOnly, getMMMddyyyy } from './dateFormatter';
import { isSafariOrFirefox, openInCurrentTab } from './openInNewTab';
import { getFileExtension } from 'utils/helper';
import { ImageIcon } from 'assets/icons/CredentialsWallet/ImageIcon';
import { SubmissionsIcon } from 'assets/icons/CredentialsWallet/Submissions';
import { ICard, ICardDetails } from 'interfaces/PageLayout/ICardListPage';
import { CertificationsIcon } from 'assets/icons/Certificate/CertificationsIcon';
import { HumanResourcesIcon } from 'assets/icons/CredentialsWallet/HumanResources';
import { EmployeeHealthIcon } from 'assets/icons/CredentialsWallet/EmployeeHealth';
import { ClinicalQualificationsIcon } from 'assets/icons/CredentialsWallet/ClinicalQualifications';
import CheckBox from 'components/common/CheckBox/CheckBox';
import CloseIcon from 'assets/icons/Close/Close';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { Badge, Box, Button, Grid, Typography } from '@mui/material';
import ActionRequiredDialog from 'components/common/Dialog/ActionRequiredDialog';
import { DownloadIcon as DocuSignIcon } from 'assets/icons/Download/Download';
import { AMNSnackbar } from 'components/common/Alert/AMNSnackbar';
import DocumentPreview from 'components/common/DocumentPreview/DocumentPreview';
import { ConfirmationPopup } from 'components/common/ConfirmationPopup/ConfirmationPopup';
import { OpenRequirementsMenu } from 'components/Credentials/OpenRequirements/OpenRequirementsMenu';
import { trackEvent } from 'services/logging/appInsights';
import { IAssignmentCredentialSubmission } from 'store/slices/credentials/credentialState';
import { OpenRequirementsIcon } from 'assets/icons/CredentialsWallet/OpenRequirements';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import { ChecklistDescription } from 'enums/checklistDescriptionType';
import { IActionItemMenuOptions } from 'enums/actionItemMenuOptions';
import ContractsIcon from 'assets/icons/Assignments/Contracts/ContractsIcon';
import ProfileIcon from 'assets/icons/Assignments/ProfileIcon/Profile';
import { TaskIcon } from 'assets/icons/TaskIcon';
import TimePayIcon from 'assets/icons/Assignments/TimePay/TimePayIcon';
import { StrikeIcon } from 'assets/icons/StrikeIcon';
import { actionItemTypeId } from 'enums/actionType';
import { MenuItems } from 'enums/menuItems';
import { AppRouteNames } from 'routes/appRouteNames';
import { ProfileReadiness } from 'constants/credentials';
import { addRedirectUrl } from './appendRedirectUrl';

export const itemsOtherProps = (
  type: ICredentialDetails,
  setSelectedDetailsItem?: any,
  setShowDetailsDrawer?: any,
  dispatch?: any,
  downloadActive?: boolean,
  toggleDownloads?: any,
  downloadList?: any,
) => {
  let rProps: any;
  let icon: any;
  let subtitle: string = '';
  switch (type.categoryId) {
    case userCredentials.OpenRequirements:
      icon = (
        <DocumentUploadsIcon htmlColor={theme.palette.system.midnightBlue} />
      );
      break;
    case userCredentials.LICENSES:
      icon = <LicensesIcon htmlColor={theme.palette.system.coolGray} />;
      break;
    case userCredentials.CERTIFICATIONS:
      icon = <CertificationsIcon htmlColor={theme.palette.system.coolGray} />;
      break;
    case userCredentials.EducationOnlineExams:
      icon = (
        <EducationOnlineExamsIcon htmlColor={theme.palette.system.coolGray} />
      );
      break;
    case userCredentials.HumanResources:
      icon = <HumanResourcesIcon htmlColor={theme.palette.system.coolGray} />;
      break;
    case userCredentials.EmployeeHealth:
      icon = <EmployeeHealthIcon htmlColor={theme.palette.system.coolGray} />;
      break;
    case userCredentials.ClinicalQualifications:
      icon = (
        <ClinicalQualificationsIcon htmlColor={theme.palette.system.coolGray} />
      );
      break;
    case userCredentials.Submissions:
      icon = <SubmissionsIcon htmlColor={theme.palette.system.coolGray} />;
      break;
  }
  subtitle = type?.expirationDate
    ? `${hasExpiredOrExpiring(type?.expirationDate, true)} ${getMMMddyyyy(
        type?.expirationDate || '',
      )}`
    : type?.completionDate
    ? `Completed ${getMMMddyyyy(type?.completionDate || '')}`
    : '';
  rProps = {
    icon: downloadActive ? (
      <CheckBox
        id={`${type?.id}`}
        disabled={false}
        checked={downloadList[type?.id] ?? false}
        onClick={e => {
          e?.stopPropagation?.();
          toggleDownloads(type?.id);
        }}
        sx={{ label: { marginRight: '0px', width: '34px', height: '20px' } }}
      />
    ) : (
      icon
    ),
    onClick: () => {
      trackEvent('CredentialDetailPage_Viewed');
      if (downloadActive) {
        toggleDownloads(type?.id);
      } else {
        if (!type?.isSecureDocument) {
          dispatch(credentialActions.getCredentialDetailDoc(type?.id));
        }
        setSelectedDetailsItem(type);
        setShowDetailsDrawer(true);
      }
    },
  };
  return {
    ...rProps,
    title: type.title,
    subtitle: subtitle,
  } as ICard;
};

export const itemsOtherPropsSubmission = (
  type: any,
  setSelectedSubmission: any,
  setShowDetailsDrawer: any,
) => {
  let rProps: any;
  let subtitle: string = type?.submitDate
    ? `Submitted ${getMMMddyyyy(type?.submitDate || '')}`
    : '';
  rProps = {
    icon:
      type?.type === SubmissionType.Checklist ? (
        <OpenRequirementsIcon htmlColor={theme.palette.system.coolGray} />
      ) : (
        getSubmissionIconBasedOnType(type)
      ),
    onClick: () => {
      if (
        !(
          type?.type === SubmissionType.Checklist && !type?.isDocumentUploadItem
        )
      ) {
        setSelectedSubmission(type);
        setShowDetailsDrawer(true);
      }
    },
  };
  return {
    ...rProps,
    title: type?.licenseVerificationDetails
      ? getRnOrLpnContentName(type.licenseVerificationDetails.disciplineId)
      : type.title,
    subtitle: subtitle,
    visualIndicator: {
      content:
        type?.reasonId === CredentialRejectedReason.Duplicate_Received
          ? BadgeStatus.Duplicate
          : returnBadgeStatus(
              type?.statusId,
              type?.type === SubmissionType.Checklist,
              type?.type === SubmissionType.Wallet,
            )?.toUpperCase(),
      color: visualIndicatorColor(
        returnBadgeStatus(
          type?.statusId,
          type?.type === SubmissionType.Checklist,
          type?.type === SubmissionType.Wallet,
        )?.toUpperCase(),
      ),
      sx: {
        pb: type?.submitDate ? 0 : '10px',
        pr:
          type?.type === SubmissionType.Checklist && !type?.isDocumentUploadItem
            ? '26px'
            : 0,
        '& .MuiBadge-badge': {
          fontWeight: 700,
        },
      },
    },
    disableEndAdornment:
      type?.type === SubmissionType.Checklist &&
      type?.credentialTypeId !==
        OpenRequirementCredentialType.Document_Uploads &&
      type?.credentialTypeId !==
        OpenRequirementCredentialType.License_Verification,
  } as ICard;
};

export const getSubmissionIconBasedOnType = (type: ICredentialSubmission) => {
  if (type?.licenseVerificationDetails) {
    return <LicensesIcon htmlColor={theme.palette.system.coolGray} />;
  } else {
    const extension = getFileExtension(type.fileUrl || '');

    switch (extension) {
      case 'pdf':
        return <PDFIcon htmlColor={theme.palette.system.coolGray} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'tiff':
      case 'bmp':
      case 'gif':
        return <ImageIcon htmlColor={theme.palette.system.coolGray} />;

      case 'docx':
      case 'doc':
        return <SubmissionsIcon htmlColor={theme.palette.system.coolGray} />;
      case 'html':
        return <LinksIcon htmlColor={theme.palette.system.coolGray} />;

      default:
        return <SubmissionsIcon htmlColor={theme.palette.system.coolGray} />;
    }
  }
};

export const addDownload = (
  selectedItem: ICardDetails,
  isEnabled: boolean,
  toggleDownloadDocs: () => void,
  documentDocs: () => void,
  downloadActive: boolean,
  downloadList: any,
) => {
  selectedItem.headerIcon = downloadActive ? (
    <CloseIcon width="18px" height="18px" />
  ) : (
    <DownloadIcon
      htmlColor={
        isEnabled ? theme.palette.system.skyBlue : theme.palette.system.coolGray
      }
      width="18px"
      height="18px"
    />
  );
  selectedItem.headerIconClick = isEnabled ? toggleDownloadDocs : undefined;

  selectedItem.footerContent = downloadActive ? (
    <Box
      pb={2}
      pt={2}
      sx={{
        display: 'flex',
        justifyContent: 'right',
        position: { xs: 'absolute !important', sm: 'unset !important' },
        backgroundColor: { xs: theme.palette.system.white, sm: 'none' },
      }}
    >
      <Box
        role="button"
        aria-label="download-cancel-button"
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Button
          onClick={toggleDownloadDocs}
          size="small"
          variant="link"
          id="resource-download-cancel"
        >
          CANCEL
        </Button>
      </Box>
      <Box
        role="button"
        aria-label="download-action-button"
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-end' },
        }}
      >
        <Button
          variant="primary"
          onClick={documentDocs}
          disabled={Object.keys(downloadList)?.length === 0}
          id="resource-download-button"
          sx={{ width: '150px' }}
        >
          Download
        </Button>
      </Box>
    </Box>
  ) : null;
  return selectedItem;
};

export const docuSignActionRequiredDialog = (
  showDocPopup: boolean,
  setShowDocPopup: any,
  envUrl: IDocuSignEnvelop,
) => {
  return (
    <ActionRequiredDialog
      icon={
        <DocuSignIcon
          htmlColor={theme.palette.system.coolGray}
          style={{ width: '50px', height: '48px' }}
        />
      }
      title="Directing to DocuSign"
      open={showDocPopup}
      body={
        <>
          <Typography
            variant="subtitle1"
            color="system.coolGray"
            textAlign="center"
          >
            You are about to be directed to DocuSign to complete the document.
            Once completed, you will be redirected back to Credential Center.
          </Typography>
          <Typography
            mt={3}
            variant="subtitle1"
            color="system.coolGray"
            textAlign="center"
          >
            <b>PLEASE NOTE:</b> By opening this document in AMN Passport, email
            signing will be temporarily disabled.
          </Typography>
        </>
      }
      buttons={[
        {
          id: 'go-docusign',
          text: 'Go to DocuSign',
          onClick() {
            window.open(envUrl.url, '_self');
            setShowDocPopup(false);
          },
        },
        {
          id: 'docusign-cancel',
          text: 'Cancel',
          onClick() {
            setShowDocPopup(false);
          },
        },
      ]}
    />
  );
};

export const getDrawerChildren = (
  selectedDetailsItem: any,
  selectedId: number | undefined,
) => {
  let expiredOrExpiring = hasExpiredOrExpiring(
    selectedDetailsItem?.expirationDate ?? '',
  );
  return (
    <Grid container id="credential-drawer-content">
      {selectedId === userCredentials.LICENSES && (
        <Grid container xs={12}>
          <Typography variant="subtitle1" color="system.midnightBlue">
            {selectedDetailsItem?.stateDescription}
          </Typography>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={11} pt={2}>
          <Typography variant="body1" color="system.grayText" fontWeight={600}>
            {selectedId === userCredentials.LICENSES
              ? selectedDetailsItem?.isLpnLicensure
                ? `LPN License #${selectedDetailsItem?.licenseNumber}`
                : `RN License #${selectedDetailsItem?.licenseNumber}`
              : `${selectedDetailsItem?.title}`}
          </Typography>
        </Grid>
        {expiredOrExpiring && (
          <Grid item xs={1}>
            <Badge
              badgeContent={expiredOrExpiring}
              sx={{
                '& .MuiBadge-badge': {
                  fontWeight: 700,
                  padding: '10px 12px',
                  borderRadius: '30px',
                  backgroundColor: theme.palette.system.error,
                  color: 'white',
                },
                marginTop: { xs: '20px', md: '18px' },
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12} item container direction="column" spacing={0} pt="12px">
        {selectedDetailsItem?.completionDate && (
          <Grid item xs>
            <Typography
              variant="body1"
              lineHeight="22px"
              color="system.coolGray"
            >
              {selectedDetailsItem?.isPendingSubmittedLicenseItem
                ? `Submitted `
                : `Completed `}
              {getMMMddyyyy(selectedDetailsItem?.completionDate || '')}
            </Typography>
          </Grid>
        )}
        {selectedDetailsItem?.expirationDate && (
          <Grid item xs>
            <Typography
              variant="body1"
              lineHeight="22px"
              color="system.coolGray"
            >
              Expires {getMMMddyyyy(selectedDetailsItem?.expirationDate || '')}
            </Typography>
          </Grid>
        )}
        {selectedId === userCredentials.LICENSES && (
          <Grid item xs>
            <Typography variant="body1" color="system.coolGray">
              {selectedDetailsItem?.level}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const getDrawerChildrenSubmission = (
  selectedSubmission: ICredentialSubmission | undefined,
  stateList: ICountryAndProvince[],
) => {
  let stateName = '';
  if (selectedSubmission?.licenseVerificationDetails) {
    stateName =
      stateList[Country.USA].statesAndProvinces.find(
        s =>
          s.stateAbbreviation ===
          selectedSubmission?.licenseVerificationDetails?.stateAbbreviation,
      )?.stateName || '';
  }
  const rnLpnData = selectedSubmission?.licenseVerificationDetails
    ? {
        stateDescription: stateName,
        completionDate: selectedSubmission?.submitDate,
        licenseNumber:
          selectedSubmission?.licenseVerificationDetails?.licenseNumber,
        isPendingSubmittedLicenseItem: true,
        isLpnLicensure:
          getRnOrLpnContentName(
            selectedSubmission?.licenseVerificationDetails.disciplineId,
          ) === ChecklistDescription.LICENSED_PRACTICAL_NURSE,
      }
    : undefined;
  return (
    <>
      {selectedSubmission?.statusId === CredentialSubmissionStatus.Rejected && (
        <Grid container item xs={12} mb="24px">
          <AMNSnackbar
            id="credential-submission-alert-bar"
            open={true}
            variant="error"
            message={selectedSubmission?.reason || ''}
          />
        </Grid>
      )}
      {selectedSubmission?.licenseVerificationDetails ? (
        getDrawerChildren(rnLpnData, userCredentials.LICENSES)
      ) : selectedSubmission?.isSecureDocument ? (
        <Grid
          container
          sx={{ justifyContent: 'center', alignItems: 'center', pt: '65%' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="system.coolGray"
              textAlign="center"
              data-testid="image-text"
            >
              <i>Image is disabled due to security reasons.</i>
            </Typography>
          </Grid>
        </Grid>
      ) : selectedSubmission?.fileUrl ? (
        <Box
          id="submission-document-preview"
          sx={{
            marginTop:
              selectedSubmission?.statusId ===
              CredentialSubmissionStatus.Rejected
                ? '60px'
                : 0,
            height: selectedSubmission.fileUrl.endsWith(
              'pdf' || 'doc' || 'docx',
            )
              ? '80%'
              : 'auto',
          }}
        >
          <DocumentPreview
            width="100%"
            height="100%"
            submissionDate={selectedSubmission?.submitDate}
            src={selectedSubmission.fileUrl}
            alt={`${selectedSubmission?.title}`}
          />
        </Box>
      ) : (
        <Grid container justifyContent="center" alignItems="center" pt="65%">
          <Typography variant="subtitle1" color="system.coolGray">
            <i>No document provided.</i>
          </Typography>
        </Grid>
      )}
    </>
  );
};

export const credentialSubmissionConfirmationPopup = (
  dispatch: any,
  openAddCredentialDrawer: () => void,
  isOpen: boolean,
) => {
  return (
    <ConfirmationPopup
      title="Got it!"
      content="Credentials submitted will be reviewed and validated. Once validated, they will appear on your credentials list."
      isPanelOpen={isOpen}
      isHandleProceedEnabled={true}
      handleClose={() => {
        dispatch(credentialActions.setModalState(false));
      }}
      handleProceed={() => {
        openAddCredentialDrawer();
        dispatch(credentialActions.setModalState(false));
      }}
      affirmative="Add Another"
      negative="ALL DONE"
      isTextCentered={true}
      isIcon={true}
    />
  );
};

export const formatAssignmentCredentialSubmissions = (
  submissions: IAssignmentCredentialSubmission[],
) => {
  return submissions.map(item => {
    return {
      ...item,
      title: item.description,
      categoryId: userCredentials.Submissions,
      isDocumentUploadItem:
        item?.credentialTypeId ===
          OpenRequirementCredentialType.Document_Uploads ||
        item?.credentialTypeId ===
          OpenRequirementCredentialType.License_Verification,
      type: SubmissionType.Checklist,
      typeId: 200,
    };
  });
};

export const orderCredentialSubmissions = (submittedCredentials: any) => {
  return submittedCredentials.sort((a, b) => {
    return orderBySubmitDate(a, b);
  });
};

export const orderBySubmitDate = (a, b) => {
  if (!a?.submitDate) return 1;
  if (!b?.submitDate) return -1;

  return Number(new Date(a?.submitDate)) < Number(new Date(b?.submitDate))
    ? 1
    : -1;
};

export const orderOpenRequirementsList = (a, b) => {
  if (!a?.isPendingCredentialItem && !b?.isPendingCredentialItem) {
    if (!(a?.credentialCloseDate || a?.expirationDate)) return 1;
    if (!(b?.credentialCloseDate || b?.expirationDate)) return -1;
    if (
      getFormattedDateOnly(a?.credentialCloseDate || a?.expirationDate) ===
      getFormattedDateOnly(b?.credentialCloseDate || b?.expirationDate)
    )
      return a?.title < b?.title ? -1 : 1;

    return Number(new Date(a?.credentialCloseDate || a?.expirationDate)) <
      Number(new Date(b?.credentialCloseDate || b?.expirationDate))
      ? -1
      : 1;
  } else if (a?.isPendingCredentialItem && !b?.isPendingCredentialItem)
    return 1;
  else if (!a?.isPendingCredentialItem && b?.isPendingCredentialItem) return -1;
  else {
    if (
      getFormattedDateOnly(a?.submitDate) ===
      getFormattedDateOnly(b?.submitDate)
    ) {
      return a?.title < b?.title ? -1 : 1;
    }
    return a?.submitDate < b?.submitDate ? -1 : 1;
  }
};

export const sortByDueDate = (
  reqs: IOpenRequirementsDetails[],
  openRequirementsData: any,
) => {
  return openRequirementsData(reqs).sort((a, b) => {
    return orderOpenRequirementsList(a, b);
  });
};

export const filterRedirectedOpenRequirement = (
  openReqs: IOpenRequirementsDetails[],
  credentialId: string,
  setSelectedOpenRequirement?: any,
) => {
  for (const req of openReqs) {
    const tempItem = formatForOpenRequirements(req, setSelectedOpenRequirement);
    if (tempItem.id === +credentialId) {
      if (setSelectedOpenRequirement) {
        setSelectedOpenRequirement(tempItem);
        break;
      } else return tempItem;
    }
  }
};

export const sortOpenRequirements = (
  openReqs: IOpenRequirementsDetails[],
  setSelectedOpenRequirement: any,
  expandAccordion?: string,
  setExpandAccordion?: any,
  dispatch?: any,
  skillListUrl?: string,
  history?: any,
) => {
  let openReqDict = {};
  openReqs?.forEach(req => {
    const tempItem = formatForOpenRequirements(
      req,
      setSelectedOpenRequirement,
      dispatch,
      skillListUrl,
      false,
      history,
    );
    if (openReqDict[tempItem.taskCenterCategoryTypeId]) {
      (openReqDict[tempItem.taskCenterCategoryTypeId] || {}).items.push(
        tempItem,
      );
    } else {
      openReqDict[tempItem.taskCenterCategoryTypeId] = {
        title: tempItem.taskCenterCategoryType,
        icon: openTaskIcon(tempItem.taskCenterCategoryType),
        onClick: key =>
          setExpandAccordion(
            key === expandAccordion
              ? undefined
              : tempItem.taskCenterCategoryTypeId,
          ),
        items: [tempItem],
      };
    }
  });
  Object.keys(openReqDict).map(key => {
    // Add total counts for each open requirement category
    openReqDict[key].title = (
      <>
        {openReqDict[key].title}
        <span style={{ color: '#75787b', paddingLeft: '6px' }}>
          {`(${openReqDict[key]?.items.length})`}
        </span>
      </>
    );
    // Add sort by dates for each open requirement category
    openReqDict[key].items = openReqDict[key].items.sort((a, b) => {
      return orderOpenRequirementsList(a, b);
    });
  });
  return openReqDict;
};

export const openTaskIcon = (
  type: string,
  color = theme.palette.system.midnightBlue,
) => {
  switch (type) {
    case TaskCenterCategoryType.Profile:
      return <ProfileIcon color={color} height="23px" width="23px" />;
    case TaskCenterCategoryType.Credentials:
      return <OpenRequirementsIcon htmlColor={color} />;
    case TaskCenterCategoryType.Contract:
      return <ContractsIcon color={color} height="23px" width="23px" />;
    case TaskCenterCategoryType.TimeAndPay:
      return <TimePayIcon color={color} height="23px" width="23px" />;
    case TaskCenterCategoryType.Docusign:
      return <DocuSignIcon htmlColor={color} />;
    case TaskCenterCategoryType.Strike:
      return <StrikeIcon color={color} height="23px" width="23px" />;
    default:
      return <TaskIcon color={color} height="23px" width="23px" />;
  }
};

export const taskCenterCategoryforOrdering = (type: string) => {
  switch (type) {
    case TaskCenterCategoryType.Profile:
      return TaskCenterCategoryTypeId.Profile;
    case TaskCenterCategoryType.Credentials:
      return TaskCenterCategoryTypeId.Credentials;
    case TaskCenterCategoryType.Contract:
      return TaskCenterCategoryTypeId.Contract;
    case TaskCenterCategoryType.TimeAndPay:
      return TaskCenterCategoryTypeId.TimeAndPay;
    case TaskCenterCategoryType.Docusign:
      return TaskCenterCategoryTypeId.Docusign;
    case TaskCenterCategoryType.Strike:
      return TaskCenterCategoryTypeId.Strike;
    default:
      return TaskCenterCategoryTypeId.General;
  }
};

export const formatForOpenRequirements = (
  req: IOpenRequirementsDetails,
  setSelectedOpenRequirement: any,
  dispatch?: any,
  skillListUrl?: string,
  fromHome?: boolean,
  history?: any,
) => {
  let onClickAction = {} as any;
  // Can format by what action type is being called
  if (req.actionItem) {
    onClickAction = {
      ...req.actionItem,
      title: req.actionItem.title,
      expirationDate: req.actionItem.expirationDate || '',
      instructions: req.actionItem.title,
      credentialSubtype: CredentialSubType.Action_Item,
      hasMenuItems:
        // Add back when dismiss is needed for the below action types
        (req?.actionItem?.actionItemTypeId ?? 0) >= ProfileReadiness.MIN &&
        (req?.actionItem?.actionItemTypeId ?? 0) <= ProfileReadiness.MAX
          ? false
          : true,
      selectFromMenu: <OpenRequirementsMenu card={req} />,
      options: [
        IActionItemMenuOptions[1],
        IActionItemMenuOptions[2],
        IActionItemMenuOptions[3],
      ],
    };
  } else if (req.survey)
    onClickAction = {
      ...req.survey,
      credentialSubtype: CredentialSubType.Action_Item,
      actionItemTypeId: actionItemTypeId.Survey,
      // Add back when survey dismiss is added
      // hasMenuItems: true,
      // selectFromMenu: <OpenRequirementsMenu card={req} />,
      // options: [
      //   IActionItemMenuOptions[1],
      //   IActionItemMenuOptions[2],
      //   IActionItemMenuOptions[3],
      // ],
    };
  else if (req.contract)
    onClickAction = {
      ...req.contract,
      credentialSubtype: CredentialSubType.Action_Item,
    };
  else if (req.skillsChecklist)
    onClickAction = {
      ...req.skillsChecklist,
      title: req.skillsChecklist.type,
      expirationDate: req.skillsChecklist.latestCompletedDate || '',
      credentialSubtype: CredentialSubType.Skills_Checklist,
    };
  else if (req.assignmentCredential)
    onClickAction = {
      ...req.assignmentCredential,
      title: req.assignmentCredential.description,
      expirationDate: req.assignmentCredential.expirationDate || '',
      typeId: req.assignmentCredential.linkTypeId,
      credentialSubtype: CredentialSubType.Assignment_Credential,
      hasMenuItems:
        req.assignmentCredential.linkTypeId === Number(LinkType.Web),
      selectFromMenu:
        req.assignmentCredential.linkTypeId === Number(LinkType.Web) ? (
          <OpenRequirementsMenu card={req.assignmentCredential} />
        ) : (
          <></>
        ),
      isPendingCredentialItem:
        req.assignmentCredential.statusId ===
          CredentialOpenRequirementsStatus.Pending ||
        req.assignmentCredential.statusId ===
          CredentialOpenRequirementsStatus.Accepted,
    };
  else if (req.userCredential)
    onClickAction = {
      ...req.userCredential,
      expirationDate: req.userCredential.expirationDate || '',
      credentialType: req.userCredential.type,
      credentialTypeId: req.userCredential.typeId,
      type:
        (req.userCredential.links || []).length > 1
          ? 'multiple'
          : 'mobileDeepLink',
      typeId:
        (req.userCredential.links || []).length > 1
          ? +LinkType.Multiple
          : +LinkType.MobileDeepLink,
      credentialSubtype: CredentialSubType.User_Credential,
      isPendingCredentialItem:
        req.userCredential.statusId === CredentialSubmissionStatus.Pending,
    };
  else if (req.docusignEnvelope) {
    onClickAction = {
      title: req.docusignEnvelope.subject,
      envelopeId: req.docusignEnvelope.envelopeId,
    };
  }

  onClickAction = {
    ...onClickAction,
    credentialTypeId: req.credentialTypeId,
    taskCenterCategoryType:
      req.taskCenterCategoryType === TaskCenterCategoryType.None
        ? TaskCenterCategoryType.General
        : req.taskCenterCategoryType,
    taskCenterCategoryTypeId: taskCenterCategoryforOrdering(
      req.taskCenterCategoryType,
    ),
    subtitle: onClickAction?.isPendingCredentialItem
      ? `Submitted ${getFormattedDateOnly(
          onClickAction?.submitDate || '',
          'MMM DD, YYYY',
        )}`
      : onClickAction?.credentialCloseDate
      ? `Due ${getFormattedDateOnly(
          onClickAction?.credentialCloseDate || '',
          'MMM DD, YYYY',
        )}`
      : onClickAction?.expirationDate
      ? `${hasExpiredOrExpiring(
          onClickAction?.expirationDate,
          true,
        )} ${getFormattedDateOnly(
          onClickAction?.expirationDate || '',
          'MMM DD, YYYY',
        )}`
      : '',
    visualIndicator: {
      content: fromHome
        ? '!'
        : onClickAction?.credentialCloseDate || onClickAction.expirationDate
        ? returnBadgeStatus(
            onClickAction?.statusId,
            !!req.assignmentCredential,
            !!req.userCredential,
            getFormattedDateOnly(
              onClickAction?.credentialCloseDate ||
                onClickAction?.expirationDate,
              'MMM DD, YYYY',
            ),
            onClickAction?.reasonId,
          )
        : undefined,
      sx:
        onClickAction?.isPendingCredentialItem &&
        req.credentialTypeId !==
          OpenRequirementCredentialType.Document_Uploads &&
        req.credentialTypeId !==
          OpenRequirementCredentialType.License_Verification
          ? {
              pr: '30px',
              '& .MuiBadge-badge': {
                fontWeight: 700,
              },
            }
          : {
              '& .MuiBadge-badge': {
                fontWeight: 700,
              },
            },
      color: visualIndicatorColor(
        returnBadgeStatus(
          onClickAction?.statusId,
          !!req.assignmentCredential,
          !!req.userCredential,
          getFormattedDateOnly(
            onClickAction?.credentialCloseDate || onClickAction?.expirationDate,
            'MMM DD, YYYY',
          ),
          onClickAction?.reasonId,
        ),
      ),
    },
    visualIndicator2: {
      content: returnUrgencyIcon(req?.actionItem?.actionItemTypeId),
      sx: {
        '& .MuiBadge-badge': {
          fontWeight: 700,
        },
      },
      color: 'system.error',
    },
    icon: openTaskIcon(
      req.taskCenterCategoryType,
      theme.palette.system.coolGray,
    ),
    sx:
      (onClickAction?.credentialCloseDate || onClickAction.expirationDate) &&
      !!returnBadgeStatus(
        onClickAction?.statusId,
        !!req.assignmentCredential,
        !!req.userCredential,
        getFormattedDateOnly(
          onClickAction?.credentialCloseDate || onClickAction?.expirationDate,
          'MMM DD, YYYY',
        ),
        onClickAction?.reasonId,
      ) &&
      !onClickAction?.isPendingCredentialItem
        ? {
            '& #cardDetails_subtitle-container': {
              color: `${theme.palette.system.error} !important`,
            },
          }
        : undefined,
    disableEndAdornment:
      onClickAction?.isPendingCredentialItem &&
      req.credentialTypeId !== OpenRequirementCredentialType.Document_Uploads &&
      req.credentialTypeId !==
        OpenRequirementCredentialType.License_Verification,
  };

  return {
    ...onClickAction,
    onClick: () => {
      if (onClickAction?.credentialSubtype === CredSubType.ActionItem) {
        dispatch(
          credentialActions.setSelectedActionMenuItem({
            menuItem: MenuItems.GO_TO_ITEM,
            taskId: onClickAction?.id,
          }),
        );
      } else setSelectedOpenRequirement(onClickAction);
      if (isSafariOrFirefox) {
        if (
          onClickAction?.credentialSubtype === CredSubType.SkillsChecklist ||
          (onClickAction?.credentialSubtype ===
            CredSubType.AssignmentCredential &&
            onClickAction?.credentialTypeId ===
              OpenRequirementCredentialType.Skills_Checklists)
        ) {
          const tempURL =
            skillListUrl +
            `sk/${onClickAction?.id}` +
            `?redirectURL=${window.location.origin}/${AppRouteNames.TASK_CENTER}?` +
            (onClickAction?.credentialSubtype ===
            CredSubType.AssignmentCredential
              ? encodeURIComponent(
                  `placementId=${onClickAction?.placementId}&credentialId=${onClickAction?.id}&credentialType=${TaskCenterCategoryTypeId.Credentials}`,
                )
              : encodeURIComponent(
                  `credentialType=${TaskCenterCategoryTypeId.Credentials}`,
                ));
          openInCurrentTab(tempURL);
          dispatch(credentialActions.setFormStackData(undefined));
        } else if (onClickAction?.typeId === +LinkType.Formstack) {
          const formStackData = onClickAction?.links?.filter(
            r => String(r.typeId) === LinkType.Formstack,
          )[0];
          const originalUrl = formStackData?.url?.substring(
            formStackData?.url?.indexOf('forms/'),
          );
          const redirectUrl =
            `${window.location.origin}/${AppRouteNames.TASK_CENTER}?` +
            encodeURIComponent(
              `placementId=${onClickAction?.placementId}&credentialId=${
                onClickAction?.id
              }&credentialType=${
                TaskCenterCategoryTypeId.Credentials
              }&formstackSubmitted=${true}`,
            );
          const docUri = addRedirectUrl(originalUrl, redirectUrl);
          dispatch(credentialActions.getFormStackDataAction(docUri));
        }
      }
    },
  };
};

export const formatActionSurveyContracts = openTasks => {
  return openTasks.map(task => {
    if (!!task?.survey) {
      return {
        taskCenterCategoryTypeId: task?.taskCenterCategoryTypeId,
        taskCenterCategoryType: task?.taskCenterCategoryType,
        actionItemTypeId: actionItemTypeId.Survey,
        survey: task.survey,
      };
    } else if (!!task?.contract) {
      return {
        taskCenterCategoryTypeId: task?.taskCenterCategoryTypeId,
        taskCenterCategoryType: task?.taskCenterCategoryType,
        actionItemTypeId: task?.contract?.actionItemTypeId,
        contract: task.contract,
      };
    }
    return {
      ...task,
      actionItemTypeId: task?.actionItem?.actionItemTypeId,
      actionItemType: task?.actionItem?.actionItemType,
    };
  });
};
